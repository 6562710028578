import axios from "@/axios.js";

export default {
  async fetchAirports({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/airport`);
      commit("setAirports", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchAirport({ commit }, params) {
    try {
      const { data } = await axios.get(`/vancynet/airport/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeAirport({ commit }, params) {
    try {
      const { data } = await axios.post(`/vancynet/airport`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateAirport({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/airport/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeAirport({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/vancynet/airport/${params}`);
      dispatch("fetchAirports");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
